import { render, staticRenderFns } from "./Deals.CO.NZ.vue?vue&type=template&id=d7112b56"
import script from "./Deals.CO.NZ.vue?vue&type=script&lang=js"
export * from "./Deals.CO.NZ.vue?vue&type=script&lang=js"
import style0 from "./Deals.CO.NZ.vue?vue&type=style&index=0&id=d7112b56&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib'
import { VCardText } from 'vuetify/lib'
import { VCarousel } from 'vuetify/lib'
import { VCarouselItem } from 'vuetify/lib'
import { VContainer } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VImg } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
installComponents(component, {
  VCard,
  VCardText,
  VCarousel,
  VCarouselItem,
  VContainer,
  VFlex,
  VImg,
  VLayout,
})
