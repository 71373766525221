<template>
  <v-container pa-0 fluid>
    <v-layout row>
      <v-flex>
        <v-card flat tile dark class="hero-home-slider">
          <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
            <v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2023/07/hero-deals-lake-matheson-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.35) 15%, rgba(22,22,22,0.1) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <h1 class="white--text display-1 font-weight-bold mb-3">Deals</h1>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only hidden-sm-and-up">
                  <small>Roys Peak Track, Wanaka</small>
                </div>
              </v-img>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-flex>
    </v-layout>
    <div class="gradient-bar"></div>

    <v-layout row>
      <v-flex mb-5>
        <v-container grid-list-xl fill-height>
          <v-layout row wrap fill-height my-0>
            <v-flex xs12 py-0 my-0>
              <v-card tile class="pa-0 elevation-0 deals-content" color="transparent">
                <v-layout row wrap my-0>
                  <v-flex x12 sm6 lg6 pt-0 pb-4 v-for="(deal, n) in deals" :key="n">
                    <v-card
                      tile
                      class="pa-0 elevation-0  dash-rounded"
                      :to="deal.link"
                    >
                      <v-img
                        :src="deal.thumbnail"
                        gradient="to bottom, rgba(22,22,22,0.15), rgba(22,22,22,0.25)"
                      >
                        <v-container fill-height>
                          <v-layout dark column fill-height justify-space-between>
                            <v-flex text-xs-center class="home-hero-cta deal-title white--text display-1 font-weight-medium
                            "><img :src="deal.art" :alt="deal.title" class="deal-art" :width="deal.width" :height="deal.height" /></v-flex>
                          </v-layout>
                        </v-container>
                      </v-img>
                      <v-card-text class="pa-4 d-block">
                        <span class="title d-block mb-2">{{deal.title}}</span>
                        <span class="subheading d-block grey--text">{{deal.description}}</span>
                      </v-card-text>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    deals : [
      {
        title: 'Great Rail Journeys of New Zealand',
        description: "Discover a collection of our most popular Independent Rail Holidays and Guided Rail Tours.",
        link: '/deals/rail-journeys/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/deals-collection-great-rail-journeys-nz.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/great-rail-journeys-of-new-zealand-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'Motorhome Madness',
        description: "Save 10% off the daily vehicle rate!",
        link: '/deals/motorhome-madness/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/09/deals-collection-motorhomes.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/08/motorhome-madness-stacked.png',
        width: 300,
        height: 210
      },
      {
        title: 'Warm Up in Australia',
        description: "Follow the sun with our latest tropical holiday collection.",
        link: '/deals/warm-up-australia/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2023/03/deals-collection-warm-up-australia-2023.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/warm-up-in-australia-2024-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'Drive the Dream | Western Australia',
        description: "Leave the everyday behind and hit the open road in Western Australia.",
        link: '/deals/western-australia/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/Thumbnail-1280-x-980-WA.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/02/drive-the-dream-western-australia-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'Best of Queensland, Australia',
        description: "Unrivalled landscapes, pristine beaches and over 300 days of sunshine.",
        link: '/deals/queensland/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/01/deal-collection-queensland.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/01/best-of-queensland-australia-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'South Pacific Island Paradise',
        description: "Find paradise in the South Pacific with these special offers.",
        link: '/deals/south-pacific/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2021/11/deals-collection-south-pacific-nzd.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2021/11/south-pacific-island-paradise-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'Aussie Highlights',
        description: "Add a special stay onto your holiday down under.",
        link: '/deals/australia-highlights/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/deal-collection-aussie-hl.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/aussie-highlights-stacked.png',
        width: 240,
        height: 150
      },
      /*
      {
        title: 'Reward Your Wonder in South Australia',
        description: "Dramatic coastlines, world-class wineries and a lot more.",
        link: '/deals/south-australia/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/deal-collection-south-australia-1.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/south-australia-deals-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'Signature Australian Experiences Collection',
        description: "A carefully curated selection of Australia’s most outstanding holidays.",
        link: '/deals/signature-australia/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/08/deals-collection-signature-australia.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/08/signature-australian-experiences-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'Secrets of Hurunui',
        description: "Discover endless reasons to visit South Island’s hidden gem.",
        link: '/deals/hurunui/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/08/deals-collection-hurunui.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/08/secrets-of-hurunui-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'Australia’s Nature Coast Drive',
        description: "Get on the road and embark on a Queensland journey of natural beauty.",
        link: '/deals/australia-nature-coast-drive/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/02/deals-collection-australias-nature-coast-drive-2.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/02/australias-nature-coast-drive-deals-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'Visit Kaikōura this winter',
        description: "Forget the crowds and cosy up to wildlife this winter.",
        link: '/deals/kaikoura/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/05/deals-collection-visit-kaikoura-this-winter.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/05/visit-kaikoura-this-winter-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'Discover Australia’s Nature Coast Drive',
        description: "Take an epic adventure across one of Queensland’s most abundantly natural regions.",
        link: '/deals/australia-nature-coast/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/02/deals-collection-australias-nature-coast-drive-2.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/02/australias-nature-coast-drive-deals-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'Iconic Aussie Bucket List Adventures',
        description: "The holiday you've been waiting your whole life to take.",
        link: '/deals/australia-bucket-list/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/01/deals-collection-australia-bucket-list-aud.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/02/deal-iconic-aussie-bucket-list-2022-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'Ocean, Alpine & Sea Touring Route',
        description: "Catch your breath on the road less travelled, featuring Kaikoura, Hurunui and West Coast.",
        link: '/deals/ocean-alpine-sea/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2021/11/deals-collection-ocean-alpine-sea-nzd.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2021/11/ocean-alpine-sea-nzd-deals-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'Backyard Bargains',
        description: "Get a great deal on a holiday in your own backyard.",
        link: '/deals/new-zealand/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2021/08/deals-collection-new-zealand-domestic.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2021/08/backyard-bargains-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'Australia Package Collection',
        description: "A spotlight on some of our favourite holidays from the Australia Package Collection.",
        link: '/deals/australia-package-collection/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2021/06/deals-collection-apc-nzd.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2021/06/apc-deals-stacked-2.png',
        width: 240,
        height: 150
      },
      {
        title: 'Hot Deals on Aussie Holidays',
        description: "Warm up in Australia with one of these hot holiday offers.",
        link: '/deals/hot-deals-australia/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2021/04/deals-collection-aussie-holidays-nz-3.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2021/04/hot-deals-on-aussie-holidays-stacked.png',
        width: 240,
        height: 150
      },*/
    ]
  }),
  mounted: function () {
    const el = document.scrollingElement || document.documentElement;
    el.scrollTop = 0;
  },
  methods: {
    top() {
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style>
.gradient-bar {
  width: 100%;
  height: 9px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#65b32e),
    color-stop(#d2d2d2),
    to(#30302f)
  );
  background: linear-gradient(90deg, #65b32e, #d2d2d2, #30302f);
}

.deals-content {
  margin-top: -60px;
}

.deal-title span{
  display:block;
  max-width: 75%;
}
</style>
