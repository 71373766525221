<template>
  <v-container pa-0 fluid>
    <v-layout row>
      <v-flex>
        <v-card flat tile dark class="hero-home-slider">
          <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
            <v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2023/07/hero-tranzalpine-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.45) 15%, rgba(22,22,22,0.25) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <img src="https://cdn.anzcro.com.au/wp-content/uploads/2023/07/great-rail-journeys-of-new-zealand-stacked.png" alt="Great Rail Journeys of New Zealand" class="campaign-title stacked" width="480" height="300" />
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only">
                  <small>TranzAlpine Train Journey - Photo: KiwiRail</small>
                </div>
              </v-img>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-flex>
    </v-layout>
    <div class="gradient-bar"></div>

    <v-layout row>
      <v-flex mb-5>
          <v-container grid-list-lg fill-height>
            <v-layout row wrap fill-height>
              <v-flex xs12 py-0 my-0>
                <v-card tile class="pa-0 elevation-0 deals-content">
                  <v-card-text class="pa-5">
                    <v-layout row wrap align-center>
                      <v-flex xs12 md10 py-0 my-0>
                        <span 
                          class="headline font-weight-medium kiwiGreen d-block mb-3"
                        >Discover a collection of our most popular Independent Rail Holidays and Guided Rail Tours.</span>
                        <p>
                          Climb aboard one of New Zealand’s Great Rail Journeys. Discover three spectacular rail journeys, each showcasing a unique view of New Zealand’s famous scenery. From sleeping volcanoes to cascading waterfalls, sparkling cities and rural farmsteads, golden beaches and snow-capped mountains, you will enjoy an immersive experience and a deeper connection with Aotearoa New Zealand.
                        </p>
                        <p>
                          Download a copy of our 24-page eBrochure which will highlight details of each New Zealand train journey, a collection of our most popular independent rail holiday packages and brand new guided rail tours which offer a more inclusive group experience with a local Tour Director.
                        </p>
                      </v-flex>
                      <v-flex xs12 md2 py-0 my-0 text-xs-center>
                        <a target="_blank" href="https://issuu.com/holidayexperts/docs/great_rail_journeys_e-brochure_25-26_nzd?fr=sOTE0YzYzMjMxNDE">
                          <img src="https://cdn.anzcro.com.au/wp-content/uploads/2024/09/Brochure-Cover-Image-scaled.jpg" alt="Great Rail Journeys of New Zealand eBrochure Cover" class="campaign-sticker kirra-early-bird" width="124" height="174" />
                        </a>
                        <small class="kiwiGreen d-block mt-2" style="line-height:1.2;">
                          <a target="_blank" href="https://issuu.com/holidayexperts/docs/great_rail_journeys_e-brochure_25-26_nzd?fr=sOTE0YzYzMjMxNDE">
                            <b>View eBrochure</b>
                          </a>
                        </small>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs12 py-0 my-0 v-for="(section, index) in sections" :key="index">
                <v-card tile dark class="pa-0 ma-0 elevation-0">
                  <v-card-text class="px-3 py-2">
                    <span
                      class="headline font-weight-medium d-block"
                    >{{section.title}}</span>
                  </v-card-text>
                </v-card>
                <v-card tile class="px-3 py-3 elevation-0">
                  <v-layout row wrap ma-0>
                    <v-flex xs12 pa-0 hidden-sm-and-down>
                      <v-layout row wrap my-0>
                        <v-flex xs12 md7 lg8>
                          <span class="caption grey--text">Tour / Package</span>
                        </v-flex>
                        <v-flex xs12 md3 lg2 text-md-right>
                          <span class="caption grey--text">From Price (NZD)</span>
                        </v-flex>
                        <v-flex xs12 md2 lg2 text-md-right>
                          <span class="caption grey--text">Inclusions & Terms</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 pa-0 v-for="(deal, dindex) in section.deals" :key="dindex">
                      <v-divider></v-divider>
                      <v-layout row wrap align-center my-3>
                        <v-flex xs12 md7 lg8>
                          <v-layout row align-center>
                            <v-flex shrink hidden-md-and-down>
                              <v-img :src="deal.thumbnail" class="deal-thumbnail"></v-img>
                            </v-flex>
                            <v-flex>
                              <span class="title d-block font-weight-medium mb-2">{{deal.name}}</span>
                              <span class="caption d-block mb-1 grey--text"><b>Dates:</b> {{deal.valid}}</span>
                              <span class="d-block package-data">
                                <v-chip label color="#f5f5f5" disabled class="mb-0">
                                  <b>Start:</b>&nbsp;<span class="package-start-location">{{deal.location}}</span>
                                  <v-tooltip top>
                                      <template v-slot:activator="{ on }">
                                        <v-btn small icon v-on="on" class="mr-0" v-on:click="copyLocation"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                      </template>
                                      <span>Copy Start location</span>
                                  </v-tooltip>
                                </v-chip>
                                <v-chip label color="#f5f5f5" disabled class="mb-0">
                                  <b>Code:</b>&nbsp;<span class="package-code">{{deal.code}}</span>
                                  <v-tooltip top>
                                      <template v-slot:activator="{ on }">
                                        <v-btn small icon v-on="on" class="mr-0" v-on:click="copyCode"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                      </template>
                                      <span>Copy Package Code</span>
                                  </v-tooltip>
                                </v-chip>
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 sm6 md3 lg2 text-md-right>
                          <span class="headline d-block font-weight-medium mb-2 kiwiGreen">${{parseInt(deal.price).toLocaleString()}}<sup>*</sup></span>
                          <span class="caption d-block grey--text">{{deal.complement}}</span>
                        </v-flex>
                        <v-flex xs12 sm6 md2 lg2 text-sm-right text-md-center>
                          <v-tooltip top v-if="deal.sm_tile">
                            <template v-slot:activator="{ on }">
                              <v-btn large v-on="on" icon class="card-with-border elevation-0 mr-1 px-0" color="white" :href="deal.sm_tile" target="_blank"><v-icon :color="kiwiGreen" size="22" class="">image</v-icon></v-btn>
                            </template>
                            <span>View Social Media Tile</span>
                          </v-tooltip>
                          <v-tooltip top v-if="deal.itinerary">
                            <template v-slot:activator="{ on }">
                              <v-btn large v-on="on" icon class="card-with-border elevation-0 mx-0 px-0" :color="kiwiGreen" :href="deal.itinerary" target="_blank"><v-icon color="white" size="22" class="">description</v-icon></v-btn>
                            </template>
                            <span>View Flyer</span>
                          </v-tooltip>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
      </v-flex>
    </v-layout>


  <v-snackbar :color="kiwiGreen" v-model="copied" bottom class="mb-4" timeout="2500">{{copiedText}} <v-btn flat @click="copied = false">Close</v-btn></v-snackbar>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    copied: false,
    copiedText: '',
    sections : [      
      {
        title : 'Independent Rail Holidays',
        deals : [
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/deal-rail-01.jpg',
            name : '7 Day NZ Rail Experience',
            price : '1719',
            complement : 'per person (twin share)',
            code : 'ANZ257DNRE',
            location : 'Auckland (AKL)',
            valid: '03 Sep 2025 - 25 Mar 2026',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/09/7-Day-NZ-Rail-Experience-NZD.pdf',
            sm_tile: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/09/7-Day-NZ-Rail-Experience-NZD-Tile-scaled.jpg'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/deal-rail-03.jpg',
            name : '10 Day Southern Tracks',
            price : '1929',
            complement : 'per person (twin share)',
            code : 'ANZ2510DST',
            location : 'Christchurch (CHC)',
            valid: '01 Sep 2025 - 31 Mar 2026',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/09/10-Day-Southern-Tracks-NZD.pdf',
            sm_tile: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/09/10-Day-Southern-Tracks-NZD-Tile-scaled.jpg'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/deal-rail-02.jpg',
            name : '10 Day Northern Showcase',
            price : '2089',
            complement : 'per person (twin share)',
            code : 'ANZ2510DNS',
            location : 'Auckland (AKL)',
            valid: '03 May 2025 - 31 Mar 2026',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/09/10-Day-Northern-Showcase-NZD.pdf',
            sm_tile: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/09/10-Day-Northern-Showcase-NZD-Tile-scaled.jpg'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/deal-rail-04.jpg',
            name : '12 Day Grand Rail Explorer',
            price : '3355',
            complement : 'per person (twin share)',
            code : 'ANZ2512DGRE',
            location : 'Queenstown (ZQN)',
            valid: '01 Sep 2025 - 16 Mar 2026',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/09/12-Day-Grand-Rail-Explorer-NZD.pdf',
            sm_tile: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/09/12-Day-Grand-Rail-Explorer-NZD-Tile-scaled.jpg'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/10/10d-luxury-rail-explorer_thumbnail-136x136-1.jpg',
            name : '12 Day Luxury Grand Rail Explorer',
            price : '6289',
            complement : 'per person (twin share)',
            code : 'ANZ2512DGLRE',
            location : 'Queenstown (ZQN)',
            valid: '01 Sep 2025 - 16 Mar 2026',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/09/12-Day-Lux-Grand-Rail-Explorer-NZD.pdf',
            sm_tile: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/09/12-Day-Luxury-Grand-Rail-Explorer-NZD-Tile-scaled.jpg'
          },
        ]
      },
      {
        title : 'Guided Rail Tours',
        deals : [
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/09/13D-Sir-Edmund-Hillary-Explorer-136x136-1.png',
            name : '13 Day Sir Edmund Hillary Explorer',
            price : '8712',
            complement : 'per person (twin share)',
            code : 'Call ANZCRO to Book',
            location : 'Christchurch (CHC)',
            valid: '30 Sep 2025 - 14 Apr 2026',
            itinerary : 'https://issuu.com/holidayexperts/docs/great_rail_journeys_e-brochure_25-26_nzd/25?fr=sOTE0YzYzMjMxNDE'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/deal-rail-05.jpg',
            name : '15 Day Classic New Zealand Highlights',
            price : '9749',
            complement : 'per person (twin share)',
            code : 'Call ANZCRO to Book',
            location : 'Auckland (AKL)',
            valid: '12 Sep 2025 - 26 Mar 2026',
            itinerary : 'https://issuu.com/holidayexperts/docs/great_rail_journeys_e-brochure_25-26_nzd/21?fr=sOTE0YzYzMjMxNDE'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/deal-rail-06.jpg',
            name : '17 Day Signature New Zealand Up Close',
            price : '15199',
            complement : 'per person (twin share)',
            code : 'Call ANZCRO to Book',
            location : 'Auckland (AKL)',
            valid: '03 Sep 2025 - 15 Apr 2026',
            itinerary : 'https://issuu.com/holidayexperts/docs/great_rail_journeys_e-brochure_25-26_nzd/12?fr=sOTE0YzYzMjMxNDE'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/08/Flavours-of-Aotearoa-136x136-1.jpg',
            name : '18 Day Signature Flavours of Aotearoa',
            price : '16499',
            complement : 'per person (twin share)',
            code : 'Call ANZCRO to Book',
            location : 'Auckland (AKL)',
            valid: '16 Mar 2025 - 22 Mar 2026',
            itinerary : 'https://issuu.com/holidayexperts/docs/great_rail_journeys_e-brochure_25-26_nzd/17?fr=sOTE0YzYzMjMxNDE'
          },
        ]
      },
    ]
  }),
  mounted: function() {
    const el = document.scrollingElement || document.documentElement;
    el.scrollTop = 0;
  },
  methods: {
    top() {
      document.documentElement.scrollTop = 0;
    },
    copyCode(event) {
      if(event){
        let copyCode = event.target.closest('.v-chip__content').querySelector('.package-code');
        window.getSelection().selectAllChildren(copyCode);
        document.execCommand("copy");
        window.getSelection().removeAllRanges();
        //alert(copyCode.innerHTML);
        this.copiedText = 'Package code copied to clipboard';
        this.copied = true;
      }
    },
    copyLocation(event) {
      if(event){
        let copyLocation = event.target.closest('.v-chip__content').querySelector('.package-start-location');
        window.getSelection().selectAllChildren(copyLocation);
        document.execCommand("copy");
        window.getSelection().removeAllRanges();
        //alert(copyLocation.innerHTML);
        this.copiedText = 'Package start location copied to clipboard';
        this.copied = true;
      }
    }
  },
};
</script>

<style>
.gradient-bar{
  width: 100%;
  height: 9px;
  background: -webkit-gradient(linear,left top,right top,from(#65b32e),color-stop(#d2d2d2),to(#30302f));
  background: linear-gradient(90deg,#65b32e,#d2d2d2,#30302f);
}

.deals-content{
  margin-top: -60px;
}

.package-data .v-chip{
  color:#9e9e9e;
  margin-left: 0;
}

.package-data .v-chip .v-chip__content{
  padding-right: 6px;
}

.deal-thumbnail{
  width: 88px;
  height: 88px;
}
</style>